import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppRightMenu from './AppRightMenu';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';



import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

import {ToastManager} from "./components/ToastManager";
import ListarAlerta from "./components/Alerta/Listar-Alerta";
import CadastrarAlerta from "./components/Alerta/Cadastrar-Alerta";
import RoleService from "./service/RoleService";
import {Dropdown} from "primereact/dropdown";
import Dashboard from "./components/Dashboard/Dashboard";
import {Badge} from "primereact/badge";
import {OverlayPanel} from "primereact/overlaypanel";
import {Divider} from "primereact/divider";
import ListarNotificacao from "./components/Notificacoes/Listar-Notificacao";
import ListarMedias from "./components/Medias/Listar-Medias";
import NotificacaoService from "./service/NotificacaoService";
import ListarBloqueadas from "./components/Bloqueadas/Listar-Bloqueadas";


const roleService = new RoleService();
const notificacaoService = new NotificacaoService(); // Create an instance of NotificacaoService
const App = (props) => {

    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('sidebar');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(true);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(true);
    const [pinActive, setPinActive] = useState(true);
    const [activeInlineProfile, setActiveInlineProfile] = useState(true);
    const [resetActiveIndex, setResetActiveIndex] = useState(null);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [interval, setInterval] = useState(null);
    const [numeroNotificacoes, setNumeroNotificacoes] = useState(null);
    const [notificacoes, setNotificacoes] = useState([]);
    PrimeReact.ripple = true;

    const menu = roleService.menuItems();

    const routes = [
        { parent: '', label: 'Dashboard', path: 'dashboard'},

        { parent: 'Cadastros', label: 'Alertas', path: 'cadastro/alertas/listar' },

        { parent: 'Pages', label: 'Calendar' },
        { parent: 'Pages', label: 'Timeline' },
        { parent: 'Pages', label: 'Invoice' },
        { parent: 'Pages', label: 'Login' },
        { parent: 'Pages', label: 'Help' },
        { parent: 'Pages', label: 'Empty' },
        { parent: 'Pages', label: 'Access' },
        { parent: 'Start', label: 'Documentation' }
    ];
    const op = useRef(null);
    let rightMenuClick;
    let configClick;
    let menuClick;
    let searchClick = false;
    let topbarItemClick;

    useEffect(() => {
        notificacaoService.getNumeroNaoVisualizadas().then(data => {
            setNumeroNotificacoes(data.data);
        });
        getNumeroNotificacoes();
        // returned function will be called on component unmount
        return () => {
            if(interval != null) {
                window.clearInterval(interval);
            }
        }

    }, []);




    const getNotificacoes = () => {
        notificacaoService.getNotificacoesNaoVisualizadas().then(data => {
            setNotificacoes(data.data);
            visualizarNotificacoes();
        });
    }

    const getNumeroNotificacoes = () => {
       const interval =  window.setInterval(() => {
            notificacaoService.getNumeroNaoVisualizadas().then(data => {
                setNumeroNotificacoes(data.data);
            });
        },10000);
        setInterval(interval);
    }

    const visualizarNotificacoes = () => {
        notificacaoService.visualizarNotificacao().then(() => {
            setNumeroNotificacoes(0);
        });
    }

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        setResetActiveIndex(true)
        setMenuActive(false)
    }, [menuMode])

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!topbarItemClick) {
            setTopbarMenuActive(false)
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
                setResetActiveIndex(true)
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false)
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) {
            setRightMenuActive(false)
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
        searchClick = false;
    }

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    }

    const onMenuModeChange = (menuMode) => {
        setMenuMode(menuMode);
        setOverlayMenuActive(false);
    }

    const onRightMenuButtonClick = () => {
        rightMenuClick = true;
        setRightMenuActive(true)
    }

    const onRightMenuClick = () => {
        rightMenuClick = true;
    }

    const onRightMenuActiveChange = (active) => {
        setRightMenuActive(active);
    }

    const onConfigClick = () => {
        configClick = true;
    }

    const onConfigButtonClick = (event) => {
        setConfigActive(prevState => !prevState)
        configClick = true;
        event.preventDefault();
    }

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive(prevState => !prevState)
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive(prevState => !prevState)
        } else {
            setStaticMenuMobileActive(prevState => !prevState)
        }

        event.preventDefault();
    }

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false)
        setStaticMenuMobileActive(false)
    }

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevState => !prevState)
        hideOverlayMenu();
        event.preventDefault();
    }




    const onToggleMenu = (event) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false)
        }

        if (sidebarActive) {
            setSidebarStatic(prevState => !prevState)
        }

        event.preventDefault();
    }

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop())
            }, 200);
        }
    }

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    }

    const onMenuClick = () => {
        menuClick = true;
    }

    const onChangeActiveInlineMenu = (event) => {
        setActiveInlineProfile(prevState => !prevState);
        event.preventDefault();
    }

    const onRootMenuItemClick = () => {
        setMenuActive(prevState => !prevState)
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    }

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    }

    const isSlim = () => {
        return menuMode === 'slim';
    }

    const isOverlay = () => {
        return menuMode === 'overlay';
    }

    const isDesktop = () => {
        return window.innerWidth > 991;
    }


    const onInputClick = () => {
        searchClick = true
    }

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    }


    const formatCurrency = (value) => {
        if(value != null) {
            return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
        }else{
            return "";
        }
    }
    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <ToastManager  />
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div className="layout-main">

                <div style={{
                    display: 'flex',
                    marginRight: '10%',
                    marginTop: 30,
                    float: 'left',
                    justifyContent: 'flex-end'
                }}>
                    <i  onClick={(e) => {getNotificacoes(); op.current.toggle(e);}} className="pi pi-bell p-overlay-badge" style={{fontSize: '2rem', cursor: 'pointer'}}>
                        <Badge value={numeroNotificacoes}></Badge>
                    </i>
                    <OverlayPanel ref={op}>
                        <h6>Ultimas notificações</h6>
                        <Divider></Divider>
                        <div style={{height:200,width:400, overflow: 'auto'}}>
                        {notificacoes.map((notificacao, index) => (
                            <>
                                <div key={index}
                                     style={{display: 'flex', justifyContent: 'flex-start', marginTop: 10}}>
                                    <span>{notificacao.descricao}</span> &nbsp;|&nbsp; <span>{notificacao.dataPagamentoFormatada}  &nbsp;|&nbsp; {formatCurrency(notificacao.valor/100)} </span>

                                </div>
                                <Divider></Divider>
                            </>
                        ))}
                        </div>
                    </OverlayPanel>
                </div>
                <AppTopbar items={menu} menuMode={menuMode} colorScheme={props.colorScheme} menuActive={menuActive}
                           topbarMenuActive={topbarMenuActive} activeInlineProfile={activeInlineProfile}
                           onTopbarItemClick={onTopbarItemClick} onMenuButtonClick={onMenuButtonClick}
                           onSidebarMouseOver={onSidebarMouseOver} onSidebarMouseLeave={onSidebarMouseLeave}
                           onToggleMenu={onToggleMenu}
                           onChangeActiveInlineMenu={onChangeActiveInlineMenu} onMenuClick={onMenuClick}
                           onMenuItemClick={onMenuItemClick}
                           onRootMenuItemClick={onRootMenuItemClick} resetActiveIndex={resetActiveIndex}/>

                <AppMenu model={menu} onRootMenuItemClick={onRootMenuItemClick} onMenuItemClick={onMenuItemClick}
                         onToggleMenu={onToggleMenu} onMenuClick={onMenuClick} menuMode={menuMode}
                         colorScheme={props.colorScheme} menuActive={menuActive}
                         sidebarActive={sidebarActive} sidebarStatic={sidebarStatic} pinActive={pinActive}
                         onSidebarMouseLeave={onSidebarMouseLeave} onSidebarMouseOver={onSidebarMouseOver}
                         activeInlineProfile={activeInlineProfile} onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                         resetActiveIndex={resetActiveIndex}/>

                <AppBreadcrumb routes={routes} onMenuButtonClick={onMenuButtonClick} menuMode={menuMode}
                               onRightMenuButtonClick={onRightMenuButtonClick} onInputClick={onInputClick}
                               searchActive={searchActive} breadcrumbClick={breadcrumbClick}/>

                <div className="layout-main-content">
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/cadastro/alertas/listar" component={ListarAlerta} />
                    <Route path="/cadastro/alertas/cadastrar" component={CadastrarAlerta} />
                    <Route path="/cadastro/alertas/editar/:id" component={CadastrarAlerta} />
                    <Route path="/notificacoes" component={ListarNotificacao} />
                    <Route path="/bloqueadas" component={ListarBloqueadas} />
                    <Route path="/medias" component={ListarMedias} />

                </div>

                <AppFooter colorScheme={props.colorScheme} />

            </div>

            <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick} onRightMenuActiveChange={onRightMenuActiveChange} />

            <AppConfig configActive={configActive} onConfigButtonClick={onConfigButtonClick} onConfigClick={onConfigClick} menuMode={menuMode} changeMenuMode={onMenuModeChange}
                colorScheme={props.colorScheme} changeColorScheme={props.onColorSchemeChange} theme={props.theme} changeTheme={props.onMenuThemeChange}
                componentTheme={props.componentTheme} changeComponentTheme={props.onComponentThemeChange}
                ripple={ripple} onRippleChange={onRippleChange} />

        </div>
    );

}

export default App;
