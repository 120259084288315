export default class RoleService {

    static isAdmin(){
        return true;
    }

    static isWhiteLabel(){
        let user = JSON.parse(localStorage.getItem('user'));
        if(user === null){
            return false;
        }
        if(user.nivelAcesso.id == 5) {
            return true;
        }else{
            return false;
        }
    }

    static isColaborador(){
        let user = JSON.parse(localStorage.getItem('user'));
        if(user === null){
            return false;
        }
        if(user.nivelAcesso.id != 1 && user.nivelAcesso.id != 5){
            return true;
        }else {
            return false;
        }
    }



    menuItems(){
        let menu = [];

        menu.push({ label: 'Dashboard', icon: 'pi pi-home', to: '/dashboard'});
        menu.push({ label: 'Cadastros', icon: 'pi pi-pencil', items: []});

        menu[menu.length-1].items.push({ label: 'Alertas', icon: 'pi pi-briefcase', to: '/cadastro/alertas/listar' });
        menu.push({ label: 'Notificações', icon: 'pi pi-home', to: '/notificacoes'});
        menu.push({label: 'Médias', icon:'pi pi-sort', to:'/medias'})
        menu.push({ label: 'Bloqueadas', icon: 'pi pi-ban', to: '/bloqueadas'});
    return menu;
    }

}
