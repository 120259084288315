import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FilterMatchMode, FilterOperator} from "primereact/api";
import MediaPagamentoService from "../../service/MediaPagamentoService";
const ListarMedias = () => {
    const mediaPagamentoService = new MediaPagamentoService();
    const [medias, setMedias] = useState([]);
    const [loading1, setLoading1] = useState(false);
    const [filters1, setFilters1] = useState(null);

    useEffect(() => {
        initFilters1();
        initMedias();
    }, []);

    const initMedias = () => {
        setLoading1(true);
        mediaPagamentoService.getAllMediaPagamentos().then(data => {
            setMedias(data.data);
            setLoading1(false);
        }).catch(error =>{
            console.error("Erro ao carregar dados:",error);
            setLoading1(false);
        });
    }

    const initFilters1 = () =>{
        setFilters1({
            'merchant': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
            'valor': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]}
        });
    }

    const valorFormatado = (rowData) => {
        return (
            <span>{formatCurrency(rowData.mediaPagamento/100)}</span>
        );
    };


    const formatCurrency = (value) => {
        if(value != null) {
            return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
        }else{
            return "";
        }
    }


    return (
        <div className="grid table-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Médias de Pagamentos</h5>
                    <DataTable value={medias} paginator className="p-datatable-gridlines" showGridlines rows={25}
                    dataKey="id" filters={filters1} filterDisplay="menu" loading={loading1} responsiveLayout="scroll"
                    emptyMessage="Nenhuma média foi encontrada.">
                        <Column sortable field="merchant" header="Codigo do EC" filter filterPlaceholder="Procurar por Codigo" style={{minWidth: '12rem'}} />
                        <Column sortable field="valorFormatado" body={valorFormatado} header="Média de Transacionado" style={{minWidth: '12rem'}}/>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};
export default React.memo(ListarMedias, comparisonFn);